import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

interface OtherPaymentsProps {
  venmoUsername?: string;
  cashappId?: string;
  paypalEmail?: string;
  tipAmount?: number;
  isExpanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
}

function OtherPayments({ 
  venmoUsername, 
  cashappId, 
  paypalEmail, 
  tipAmount,
  isExpanded: externalExpanded,
  onExpandedChange 
}: OtherPaymentsProps) {
  const [internalExpanded, setInternalExpanded] = useState(false);
  
  const isExpanded = externalExpanded ?? internalExpanded;
  const setIsExpanded = (value: boolean) => {
    setInternalExpanded(value);
    onExpandedChange?.(value);
  };

  const hasAnyPaymentOption = venmoUsername || cashappId || paypalEmail;

  if (!hasAnyPaymentOption) return null;

  const encodedNote = encodeURIComponent("payment for service provided");

  return (
    <div className="mt-4">
      {/* Divider */}
      <div className="w-full h-px bg-gray-200 mb-4"></div>

      {/* Toggle Button */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
      >
        <span className="text-sm">other payment options</span>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-4 h-4" />
        </motion.div>
      </button>

      {/* Payment Options */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="grid gap-3 mt-4">
              {venmoUsername && (
                <a
                  href={`venmo://paycharge?txn=pay&recipients=${venmoUsername}&amount=${tipAmount || ''}&note=${encodedNote}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const appUrl = `venmo://paycharge?txn=pay&recipients=${venmoUsername}&amount=${tipAmount || ''}&note=${encodedNote}`;
                    const webUrl = `https://venmo.com/${venmoUsername}?txn=pay&amount=${tipAmount || ''}&note=${encodedNote}`;
                    
                    let appWasOpened = false;
                    window.addEventListener('blur', () => {
                      appWasOpened = true;
                    }, { once: true });

                    window.location.href = appUrl;
                    
                    setTimeout(() => {
                      if (!appWasOpened) {
                        window.location.href = webUrl;
                      }
                    }, 500);
                  }}
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#3D95CE] hover:opacity-90 transition-opacity"
                  aria-label="Pay with Venmo"
                >
                  <img src="/venmo.png" alt="Venmo" className="h-6 w-auto" />
                </a>
              )}

              {cashappId && (
                <a
                  href={`https://cash.app/$${cashappId}/${tipAmount || ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const universalUrl = `https://cash.app/$${cashappId}/${tipAmount || ''}`;
                    
                    let appWasOpened = false;
                    window.addEventListener('blur', () => {
                      appWasOpened = true;
                    }, { once: true });

                    window.location.href = universalUrl;
                  }}
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#00D632] hover:opacity-90 transition-opacity"
                  aria-label="Pay with Cash App"
                >
                  <img src="/cashapp.png" alt="Cash App" className="h-6 w-auto" />
                </a>
              )}

              {paypalEmail && (
                <a
                  href={`https://paypal.me/${paypalEmail}/${tipAmount || ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#003087] hover:opacity-90 transition-opacity"
                  aria-label="Pay with PayPal"
                >
                  <img src="/paypal.png" alt="PayPal" className="h-6 w-auto" />
                </a>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default OtherPayments;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface TipInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  presets?: number[];
}

function TipInput({ value, onChange, presets = [2, 5, 10, 20] }: TipInputProps) {
  const [customValue, setCustomValue] = React.useState('');
  const [isCustom, setIsCustom] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const MIN_AMOUNT = 1.00;

  const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    
    // Only allow numbers and a single decimal point
    if (!/^\d*\.?\d*$/.test(newValue)) {
      return;
    }
    
    setCustomValue(newValue);
    setIsCustom(true);
    setError(null);
    
    if (newValue === '') {
      setCustomValue('');
      onChange(null);
      return;
    }

    const numericValue = Number(newValue);
    if (!isNaN(numericValue)) {
      if (numericValue < MIN_AMOUNT) {
        setError(`Minimum amount is $${MIN_AMOUNT.toFixed(2)}`);
        onChange(null);
      } else {
        setError(null);
        onChange(numericValue);
      }
    }
  };

  const handlePresetClick = (amount: number) => {
    if (amount < MIN_AMOUNT) {
      setError(`Minimum amount is $${MIN_AMOUNT.toFixed(2)}`);
      onChange(null);
      return;
    }
    setIsCustom(false);
    setCustomValue('');
    setError(null);
    onChange(amount);
  };

  return (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-700">Select Tip Amount</label>
      
      <div className="grid grid-cols-4 gap-2">
        {presets.map((amount, index) => (
          <motion.button
            key={amount}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => handlePresetClick(amount)}
            className={`
              py-2 px-4 rounded-lg font-medium transition-all
              ${!isCustom && value === amount
                ? 'bg-navy-600 text-white shadow-lg scale-105'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }
              ${amount < MIN_AMOUNT ? 'opacity-50 cursor-not-allowed' : ''}
            `}
            disabled={amount < MIN_AMOUNT}
          >
            ${amount}
          </motion.button>
        ))}
      </div>

      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Custom Amount
        </label>
        <div className="relative">
          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
          <input
            type="number"
            inputMode="decimal"
            value={customValue}
            onChange={handleCustomValueChange}
            className={`w-full pl-8 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-navy-500 focus:border-transparent
              ${error ? 'border-red-300' : 'border-gray-300'}`}
            min={MIN_AMOUNT}
            step="0.01"
            placeholder={`Enter amount...`}
          />
        </div>
        <AnimatePresence>
          {error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="mt-2 text-sm text-red-600"
            >
              {error}
            </motion.p>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default TipInput;
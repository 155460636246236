import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User, Wallet } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import Logo from './Logo';

function Header() {
  const { user } = useAuth();
  const location = useLocation();
  const isProfilePage = user && location.pathname === `/${user.username}`;

  return (
    <header className="flex items-center justify-between px-4 py-2 absolute top-0 left-0 right-0 z-10 pt-safe">
      <Link to="/" className="hover:opacity-80 transition-opacity">
        <Logo />
      </Link>
      
      {user ? (
        <div className="flex items-center gap-3">
          
          <Link
            to={`/${user.username}`}
            className="flex items-center gap-3 px-4 py-2 rounded-lg hover:bg-black/5 transition-colors"
          >
            {user.profile_picture_url ? (
              <img
                src={user.profile_picture_url}
                alt={user.name}
                className="w-8 h-8 rounded-full object-cover"
              />
            ) : (
              <div className="w-8 h-8 rounded-full bg-navy-600 flex items-center justify-center">
                <User className="w-4 h-4 text-white" />
              </div>
            )}
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium">{user.name}</span>
              <span className="text-xs opacity-75">@{user.username}</span>
            </div>
          </Link>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <Link
            to="/login"
            className="text-sm hover:opacity-75 transition-opacity"
          >
            Log in
          </Link>
          <Link
            to="/signup"
            className="text-sm px-4 py-2 rounded-lg bg-navy-600 text-white hover:bg-navy-700 transition-colors"
          >
            Sign up
          </Link>
        </div>
      )}
    </header>
  );
}

export default Header;
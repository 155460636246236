import React, { useState } from 'react';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { CommentInput as CommentInputType } from '../types/comments';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

interface CommentInputProps {
  recipientId: string;  // This will now be the username
  onCommentSubmitted?: () => void;
}

function CommentInput({ recipientId, onCommentSubmitted }: CommentInputProps) {
  const [comment, setComment] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!comment.trim()) return;

    setIsSubmitting(true);
    try {
      // First, get the user's ID from their username
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', recipientId));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Recipient not found');
      }

      const userId = querySnapshot.docs[0].id;
      const commentData: CommentInputType = {
        text: comment.trim(),
        recipientId: userId,
        approved: null  // Set initial state as pending
      };

      // Only add name if it exists
      if (name.trim()) {
        commentData.name = name.trim();
      }

      await addDoc(collection(db, `users/${userId}/comments`), {
        ...commentData,
        timestamp: new Date().toISOString()
      });

      setComment('');
      setName('');  // Reset name field
      setIsSubmitted(true);
      if (onCommentSubmitted) {
        onCommentSubmitted();
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-8 mb-4 bg-white rounded-lg shadow-lg p-6"
    >
      <h2 className="text-xl font-semibold mb-4">Leave a comment</h2>
      <AnimatePresence mode="wait">
        {isSubmitted ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="text-center p-6"
          >
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <p className="text-lg font-medium text-gray-900">Comment Submitted!</p>
            <p className="text-gray-600 mt-2">Your comment has been sent.</p>
            <a 
              href={`/${recipientId}`} 
              className="inline-block mt-4 text-navy-600 hover:text-navy-700"
            >
              ← Back to {recipientId}'s page
            </a>
          </motion.div>
        ) : (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit}
            className="space-y-4"
          >
            <div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your name (optional)"
                className="w-full px-4 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                disabled={isSubmitting}
              />
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Leave a nice note!"
                className="w-full h-32 px-4 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                disabled={isSubmitting}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isSubmitting || !comment.trim()}
                className={`px-6 py-3 text-white bg-navy-600 rounded-lg hover:bg-navy-700 transition-colors focus:outline-none focus:ring-2 focus:ring-navy-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed ${
                  isSubmitting ? 'cursor-wait' : ''
                }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Comment'}
              </button>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default CommentInput;
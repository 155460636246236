import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useStripe, useElements, PaymentRequestButtonElement, PaymentElement } from '@stripe/react-stripe-js';
import { Wallet2, ChevronDown } from 'lucide-react';

interface PaymentButtonProps {
  amount?: number;
  stripeAccountId: string;
  recipientName: string;
  recipientUsername: string;
  onOtherPaymentsClick?: () => void;
}

function PaymentButton({ amount, stripeAccountId, recipientName, recipientUsername, onOtherPaymentsClick }: PaymentButtonProps) {
  const stripe = useStripe();
  const elements = useElements();
  const paymentRequestRef = useRef<any>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPaymentRequestReady, setIsPaymentRequestReady] = useState(false);

  // Initialize PaymentRequest only once when stripe is available
  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `Tip for ${recipientName}`,
        amount: 0, // Start with a minimal amount
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(result => {
      console.log('Payment Request capability result:', result); // Log the result
      if (result) {
        paymentRequestRef.current = pr;
        setCanMakePayment(true);
        setIsPaymentRequestReady(true);
      }
    });

    return () => {
      if (paymentRequestRef.current) {
        paymentRequestRef.current.removeAllListeners();
        paymentRequestRef.current = null;
      }
    };
  }, [stripe, recipientName]);

  // Handle amount changes and payment method events separately
  useEffect(() => {
    if (!paymentRequestRef.current || !amount) return;

    // Update the payment request with new amount
    paymentRequestRef.current.update({
      total: {
        label: `Tip for ${recipientName}`,
        amount: Math.round(amount * 100),
      },
    });

    // Remove previous payment method listeners
    paymentRequestRef.current.removeAllListeners('paymentmethod');

    // Add new payment method listener with current amount
    paymentRequestRef.current.on('paymentmethod', async (event) => {
      setIsProcessing(true);
      setError(null);

      try {
        const response = await fetch('/.netlify/functions/payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: Math.round(amount * 100),
            currency: 'usd',
            description: `Tip for ${recipientName}`,
            stripeAccountId,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Payment failed');
        }

        const { clientSecret } = await response.json();

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          event.complete('fail');
          throw confirmError;
        }

        event.complete('success');
        const timestamp = new Date().toISOString();
        window.location.href = `${window.location.origin}/payment/success?amount=${amount}&recipient=${recipientName}&timestamp=${timestamp}&paymentId=${paymentIntent?.id || 'unknown'}&recipientId=${recipientUsername}`;
      } catch (error) {
        console.error('Payment failed:', error);
        setError(error instanceof Error ? error.message : 'Payment failed');
        event.complete('fail');
      } finally {
        setIsProcessing(false);
      }
    });
  }, [amount, stripe, recipientName, stripeAccountId]);

  const handleRegularPayment = async () => {
    if (!stripe || !elements) {
      setError('Payment system not initialized');
      return;
    }

    if (!amount) {
      setError('Please select a tip amount');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const response = await fetch('/.netlify/functions/payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: Math.round(amount * 100),
          currency: 'usd',
          description: `Tip for ${recipientName}`,
          stripeAccountId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Payment failed');
      }

      const { clientSecret } = await response.json();

      const { error: confirmError } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/payment/success`,
          payment_method_data: {
            billing_details: {
              name: 'Anonymous Tipper',
            },
          },
        },
      });

      if (confirmError) {
        throw confirmError;
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setError(error instanceof Error ? error.message : 'Payment failed');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="w-full mt-6">
      <AnimatePresence>
        {!amount && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg"
          >
            <p className="text-sm text-yellow-700">Please select a tip amount to continue</p>
          </motion.div>
        )}

        {canMakePayment && paymentRequestRef.current && amount && isPaymentRequestReady && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mb-4"
          >
            <PaymentRequestButtonElement
              options={{
                paymentRequest: paymentRequestRef.current,
                style: {
                  paymentRequestButton: {
                    type: 'default',
                    theme: 'dark',
                    height: '48px',
                  },
                },
              }}
            />
          </motion.div>
        )}

        {(!canMakePayment || !paymentRequestRef.current || !amount) && amount && (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onOtherPaymentsClick}
            disabled={!amount}
            className={`
              w-full py-3 px-4 rounded-lg
              bg-gradient-to-r from-navy-600 to-navy-700
              text-white font-medium shadow-lg
              flex items-center justify-center gap-2
              transition-all duration-200
              ${!amount ? 'opacity-75 cursor-not-allowed' : 'hover:from-navy-700 hover:to-navy-800'}
            `}
          >
            
            <span>See Payment Options</span>
            <ChevronDown className="w-5 h-5" />
          </motion.button>
        )}

        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg"
          >
            <p className="text-sm text-red-600">{error}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default PaymentButton;